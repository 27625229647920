import React from "react";
import { StaticImage } from "gatsby-plugin-image"
import Helmet from "react-helmet";
import '../../styles/certificates.css'
import { PageFooter } from "../../components/blog-components";

const Page : React.FunctionComponent = () => {
  const Heading = "TCS Internship Certificate";

  return (
    <div className="certificates-container">
      <Helmet>
        <body className="body"/>
        <title> {Heading} </title>
      </Helmet>

      <div className="certificates-header">
        <h1> {Heading} </h1>
      </div>
      <div className="certificates-body">
        <StaticImage 
          src="../../images/tcs-internship-certificate.jpg" 
          alt="tcs-internship-certificate"
          width={1000}
        />
      </div>

      <PageFooter iconColor={"#000"} />
    </div>
  )
}

export default Page;